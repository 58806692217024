import React from 'react';
import clsx from 'clsx';
import { Typography, Theme, PropTypes } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Title: React.SFC<TitleProps> = (props) => {
  const {
    alignment,
    component,
    children,
    marginBottom,
    titleText,
    titleSize,
    titleColor,
    titleCaps,
    titleHeavy,
    isSubtitle,
    subtitleType,
    subtitleText,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    title: {
      color: titleColor ? titleColor : theme.palette.secondary.main,
      fontWeight: titleHeavy ? 600 : 400,
      textTransform: titleCaps ? 'uppercase' : 'inherit',
      marginBottom: marginBottom ? marginBottom : 'inherit',
      lineHeight: 1,
    },
    subtitleType: {
      color: theme.palette.primary.main,
      display: 'block',
      fontSize: 18,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },

      '&subtitle1': {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
      },
      '&subtitle2': {
        color: theme.palette.primary.main,
        fontSize: '1.4rem',
      },
    },
  }));

  const classes = useStyles(props);

  return (
    <React.Fragment>
      {isSubtitle && subtitleType && subtitleText && (
        <Typography
          align={alignment ? alignment : 'left'}
          className={clsx(classes.subtitleType)}
          component="span"
          variant={subtitleType}
        >
          {subtitleText}
        </Typography>
      )}
      <Typography
        align={alignment ? alignment : 'left'}
        component={component || 'h3'}
        className={classes.title}
        gutterBottom
        key="g-title"
        variant={titleSize}
      >
        <span dangerouslySetInnerHTML={{ __html: titleText }} />

        {children}
      </Typography>
    </React.Fragment>
  );
};

interface TitleProps {
  alignment?: PropTypes.Alignment;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
  children?: React.ReactNode;
  marginBottom?: number;
  titleText: string;
  titleSize:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline';
  titleHeavy?: boolean;
  titleColor?: string;
  titleCaps?: boolean;
  isSubtitle?: boolean;
  subtitleType?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline';
  subtitleText?: string;
}

export default Title;
